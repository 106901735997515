
import { Prop, Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { EmployeeClient, ImportDataClient } from '@/services/Services';
import InfoModal from '@/components/modals/infoModal.vue';
import * as OM from '@/Model';

@Options({})
export default class UploadExcelModal extends Vue {

    emptyIdentifier: string = "00000000-0000-0000-0000-000000000000";
    availableGarments: OM.TextIdentifier[] = [];
    loaded: boolean = false;

    file: File = null;
    selectedGarmentIdentifier: string = "";

    @Prop() callback: any;

    created() {
        var mixedDefault = new OM.TextIdentifier();
        mixedDefault.identifier = this.emptyIdentifier;
        mixedDefault.text = "MIX**";
        this.availableGarments.push(mixedDefault);

        EmployeeClient.getAvailableLicenses()
        .then(x => {
            x.forEach(element => {
                this.availableGarments.push(element.garment);
            });

            this.$nextTick(() => {
                this.selectedGarmentIdentifier = this.emptyIdentifier;
                this.loaded = true;
            })
        })
    }

    uploadFile() {
        ImportDataClient.createEstimationFromExcel(this.file, this.selectedGarmentIdentifier)
        .then(x => {
            this.callback();
        })
        .catch(y => {
            this.$opModal.show(InfoModal, {
                text:this.$localizationService.getLocalizedValue("Error", "Error"),
                subtitle: y ? y : this.$localizationService.getLocalizedValue("Something went wrong", "Something went wrong"),
                deny: this.$localizationService.getLocalizedValue("Close", "Close"),
                denyCb: () => {
                    this.callback();
                }
            })
        })
    }

    goToGuide() {
        const routeData = this.$router.resolve({name: 'excel-guide'});
        window.open(routeData.href, '_blank');
    }
    
}
