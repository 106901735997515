
import { Prop, Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { EstimationClient, GarmentClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({})
export default class ManageOtherSizesModal extends Vue {

    loaded: boolean = false;
    availableSizes: OM.KeyValuePairOfStringAndString[] = [];

    model: OM.CreateEstimationBySKUAndSizesVM = new OM.CreateEstimationBySKUAndSizesVM();
    activeCard: string = "";

    @Prop({
        default: () => new OM.EstimationListVm()
    }) estimation: OM.EstimationListVm;

    @Prop() callback: any;

    created() {
        this.model.existingEstimationIdentifier = this.estimation.identifier;
        this.model.existingEstimationQuantityToUpdate = this.estimation.quantity;

        GarmentClient.getGarmentEUSizeAndGender(this.estimation.garment.identifier)
        .then(x => {
            this.availableSizes = x;
            this.availableSizes = this.availableSizes.filter(x => x.value != this.estimation.euSizeValue);

            this.availableSizes.forEach(element => {
                var item = new OM.EstimationToCreateVM();
                item.gender = element.key;
                item.euSize = element.value;
                item.quantity = 1;
                this.model.estimationsToCreate.push(item);
            });

            this.activeCard = this.estimation.gender;

            this.$nextTick(() => {
                this.loaded = true;
            })
        })
    }

    get groupedSizes() {
        return this.availableSizes.reduce((acc, item) => {
            if (!acc[item.key]) {
                acc[item.key] = [];
            }
            acc[item.key].push(item.value);
            return acc;
        }, {});
    }

    save() {
        EstimationClient.createEstimationByExistingSKUForSelectedSizes(this.model)
        .then(x => {
            this.callback();
        })
    }
    
}
